import React from "react";
import { useTranslation } from "react-i18next";

export default function Country() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  return (
    <div>
      <ul>
        <li onClick={() => changeLanguage("gr")} style={{ cursor: "pointer" }}>
          <img
            src="./assets/greek.svg"
            width="25px"
            alt="Change language to Greek"
            style={{ cursor: "pointer" }}
          />
        </li>
        <li onClick={() => changeLanguage("en")} style={{ cursor: "pointer" }}>
          <img
            src="./assets/english.svg"
            alt="Change language to English"
            width="33px"
            style={{ cursor: "pointer" }}
          />
        </li>
        <li>
          <a
            className="color-blue"
            href="https://www.facebook.com/Water-Pool-198867646791105"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit our Facebook page"
          >
            <i className="bx bxl-facebook"></i>
          </a>
        </li>
        <li>
          <a
            className="color-pink"
            href="https://instagram.com/waterpools.gr"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit our Instagram"
          >
            <i className="bx bxl-instagram"></i>
          </a>
        </li>
      </ul>
    </div>
  );
}
