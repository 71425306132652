import React, { lazy, Suspense, Component } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
const load = (Component) => (props) => (
  <Suspense fallback={<div>Loading...</div>}>
    <Component {...props} />
  </Suspense>
);

const Footer = load(lazy(() => import("./components/Footer/footer.jsx")));
const HomePage = load(lazy(() => import("./components/HomePage/HomePage.jsx")));
const Company = load(lazy(() => import("./components/Company/Company.jsx")));
const Order = load(lazy(() => import("./components/Order/Order.jsx")));
const Pool = load(lazy(() => import("./components/Pool/Pool.jsx")));
const Spa = load(lazy(() => import("./components/SPA/Spa.jsx")));
const Biological = load(
  lazy(() => import("./components/Biological/Biological.jsx"))
);
const WaterCreations = load(
  lazy(() => import("./components/WaterCreations/WaterCreations"))
);
const Contact = load(lazy(() => import("./components/Contact/Contact")));

axios.defaults.baseURL = "/.netlify/functions/api";
export default class App extends Component {
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/company" component={Company} />
            <Route path="/order" component={Order} />
            <Route path="/pool" component={Pool} />
            <Route path="/spa" component={Spa} />
            <Route path="/biological" component={Biological} />
            <Route path="/waterCreations" component={WaterCreations} />
            <Route path="/contact" component={Contact} />
          </Switch>
          <Footer />
        </Router>
      </>
    );
  }
}
