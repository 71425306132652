import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Country from "./country";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Trans } from "react-i18next";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Trans i18nKey="sidebar" />
  </I18nextProvider>,
  document.getElementById("sidebar")
);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Trans i18nKey="menu_experience" />
  </I18nextProvider>,
  document.getElementById("experience")
);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Trans i18nKey="menu_home" />
  </I18nextProvider>,
  document.getElementById("home")
);
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Trans i18nKey="menu_company" />
  </I18nextProvider>,
  document.getElementById("company")
);
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Trans i18nKey="menu_services" />
    <i class="bx bx-chevron-down"></i>
  </I18nextProvider>,
  document.getElementById("services")
);
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Trans i18nKey="menu_pools" />
  </I18nextProvider>,
  document.getElementById("pools")
);
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Trans i18nKey="menu_spa" />
  </I18nextProvider>,
  document.getElementById("spa")
);
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Trans i18nKey="menu_biological" />
  </I18nextProvider>,
  document.getElementById("biological")
);
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Trans i18nKey="menu_waterCreation" />
  </I18nextProvider>,
  document.getElementById("waterCreations")
);
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Trans i18nKey="menu_order" />
  </I18nextProvider>,
  document.getElementById("order")
);
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Trans i18nKey="menu_services2" />
  </I18nextProvider>,
  document.getElementById("services2")
);
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Trans i18nKey="menu_contact" />
  </I18nextProvider>,
  document.getElementById("contact")
);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Country />
  </I18nextProvider>,
  document.getElementById("countries")
);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
