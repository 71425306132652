let en = {
  translations: {
    hpst: "Design, construction company for swimming pools (pools), biological cleaning.",
    hpst1:
      "WATERPOOL reference point for anyone who wants a swimming pool manufactured with all the rules of the technique, without but <br/> limit his desires, with high aesthetics and excellent quality. A world of clear and vitals such as water. The passion for high quality, care of the details, knowledge, and ability to are united by our tradition and modern technology have made us one of the best companies in the field. Your welcome to the WATERPOOL website.",

    hpst2:
      "Browse our website, see suggestions and contact us to enjoy your dream in your space.",
    title_form: "CONTACT FORM",
    form_question: "How can we help you?",
    offer:
      "We offer a range of prayer supplements for more demanding requirement.",
    offer2: "PLEASE CONTACT US TO GET A FREE OFFER.",
    reasons: "5 reasons to contact us today!",
    reasons_par:
      "Our many years of experience combined with the expertise of our staff, make WaterPool one of the leading companies in the field!",
    btn1: "Ask us something.",
    service: "SERVICE",
    par_service:
      "Our main priority is the prompt and quality customer service with the best service-result quality and end cost ratio.",
    serv_par:
      "The principles that govern us are our professional ethics, trust, consistency and responsibility.",
    serv_par3:
      "There is a continuous effort to continuously improve us in all areas, to have long-term cooperation and relationships of trust with customers.",
    pool: "POOL",
    spa: "HYDROMASAZ-SAUNA-SPA",
    biolog: "BIOLOGICAL CLEANINGΙ",
    waterCreation: "WATER EXPENDITURE-HYDATOCREATIONS",
    electro: "ELECTROMECHANICAL SERVICES",
    COMPANY:
      " WATER POOL is a design, construction company for private and public swimming pools. Since its establishment in 1998, the company has been active in the field of studies and supervision of Swimming Tanks, Waste and Wastewater Treatment Facilities, as well as in the provision of services of full licensing coverage of these projects<br/>  The company cooperates with specialized engineers of all specialties Mechanical, Electrical, Civil Engineers, Chemical Engineers, Spatial Planning-Planning Engineers, Surveyors, Architects to fully meet the needs of customers. ",
    histor_title: "HISTORIC",

    histor_title2: "PRINCIPLES AND OBJECTIVES",
    li2_histor2:
      "The principles that govern us are our professional ethics, trust, consistency and responsibility.",
    li_histor2:
      " Our main priority is the prompt and quality customer service with the best service-result quality and final cost ratio",
    li3_histor2:
      "There is a continuous effort to continuously improve us in all areas, to have long-term cooperation and relationships of trust with customers.                 ",

    histor_title3:
      "STUDIES- OVERVIEWS- PUBLICATIONS OF LICENCE AND OPERATION LICENCE",
    li_history3:
      " We offer integrated project management : study, execution of projects              civil engineering, procurement and installation              electromechanical equipment, start-up and supervision              Operation. We maintain constant contact with customers and              we gather observations and conclusions, which contribute to the              optimization of installations.",
    histor_title4: "GENERAL",
    histor4Paragr:
      "Preparation Monitoring and Control of Studies We undertake Studies- Supervisions- Publications of Licenses and Licenses of Operation, Swimming Pools, Biological Cleanings, etc. Initially research is carried out to determine the necessary approvals / permits as well as the necessary documents to be submitted to each Authority. Then the dossier is prepared with the required documents to be submitted and the various applications are submitted to the competent authorities and the monitoring of the issuance of licenses More specifically, in the context of the services Studies- Supervisions- Licensing Publications are provided",
    histor_title5: "STUDIES",
    histor5_paragr:
      "Studies for the issue of urban permits for swimming pools, biological cleanings",
    histor_title6: "OVERVIEWS",
    histor6_paragr:
      "Supervision of Urban Empty Swimming Pools, Organic Cleaning.",
    histor_title7: "OPERATION LICENCE PUBLICATIONS",
    histor7_paragr:
      "Issuance of a license to operate a public swimming pool, biological cleaning.",
    histor_title8: "CONSTRUCTION ",
    marketing_title: "Products in greatest demand",
    star1: "STUDY",
    star2: "SUPERVISION",
    star3: "CONSTRUCTION",
    star4: "MAINTENANCE AND OPERATION OF COLUMBIC RIGHTS",
    star5: "MAINTENANCE AND OPERATION OF BIOLOGICAL CLEANINGS",
    lista1:
      "          Construction of Public Swimming Pools, Organic Cleanings, Private              Swimming Tanks and Small Organic Facilities              Cleaning of settlements, hotels, camping etc.",
    lista2: "Maintenance-operation of your swimming pool.",
    lista3:
      "Integrated cleaning maintenance services for the flawless operation of your swimming pool or your organic cleaning.",
    paragr1:
      "  For anything related to the swimming pool or biological cleaning from issuing permits (urban planning and operating license), supply-installation of E/M Equipment until construction, operation and maintenance.",
    paragr2:
      " Ask for an offer for the dimensions of the swimming pool you desire with any construction material, (painting, tile, mosaic, etc.) and you will have the swimming pool you desire with excellent construction quality and economy.",
    paragr3:
      "    For the installation of organic cleaning of the hotel or camping etc. send the equivalent population and the M.P.E. Approval (if any) and we will send you your offer.",

    pool_par1:
      " Water Pool deals with everything related to the swimming pool and water treatment. The main occupation of the company for years is the Design -Construction of water-related projects such as Public Swimming Pools, Pools, Hydromassage, Sauna, Hammam, Spa Areas, Water Creations, Biological Cleaning Facilities, as well as surrounding configurations etc.<br /> For anything related to your swimming pool from issuing permits (urban planning and operating license), procurement-installation of E/M Equipment to construction, operation and maintenance (pool chemicals) we have the solutions.                <br /> Request an offer for the dimensions of the swimming pool you wish with any construction material,(painting, tile, mosaic, etc.)                and you will have the swimming pool you desire with excellent construction quality and economy.                <br /> We will help you choose the type and shape of your swimming pool, as well as its construction materials, how to clean and disinfect the water and generally all the details that can make your pool a source of joy and well-being.                <br /> The goal of the Water Pool website is to give you all the necessary information so that you can choose better that suits you.",
    pool_par2:
      "The swimming pools are not all the same, they differ in terms of use, mode of construction and operation.One category is that of private swimming pool for private use and another of public swimming pool for Hotels, camping, Public swimming pools.The swimming pools can be indoor or outdoor. Indoor pools are usually heated to be open all year round.There are swimming pools with skimmers for smaller dimensions or with an overflow channel for larger pools.Pools can also be made of concrete for better and more durable construction, prefabricated (with liner), or polyester (for small sizes).",
    pool_par3:
      "These pools are made of reinforced concrete, sealed and invested with paint, paint, tile or mosaic. They can be manufactured in any shape and size, they have a very long life and in terms of construction quality they are the best. Especially in a seismic country like Greece it may be the only right solution.The water pool company has specialized construction workshops because the swimming pool is a special construction and needs experience knowledge and technique.",
    pool_par4:
      "  For those who want a faster and cheaper solution for their swimming pool, we also have pools prefabricated with film lining (liner) in a wide variety of dimensions and designs.",
    pool_par5:
      "For smaller pool sizes, a quick and economical solution is polyester pools in many dimensions and designs.",
    pool_par6:
      "The swimming pool requires care and proper maintenance  to maintain an ideal state of purity and disinfection.The disinfection of the water is together with the proper construction and proper use of pumps and filters the most important thing in a swimming pool because the water should be clean and above all healthy. This can be done with appropriate chemicals and at the correct dosage or even using disinfection systems in combination with the use of chemicals. Reckless or improper use of chemicals is dangerous to health and increases maintenance costs.We will give you the appropriate instructions or if necessary and the appropriate training to always have clean and healthy water at the minimum cost and with maximum safety for your health.The scientific staff of Water Pool will give you the appropriate instructions or if necessary and the appropriate training to always have clean and healthy water with the smallest cost and maximum safety for your health.",
    pool_par7:
      "  AUTOMATIC OXIDATION AND SOCKING SYSTEM            <br />            SYSTEMS FOR RESOLVATION WITH OZON OF THE WATER OF PISINA            <br />            UV RESOLVATION SYSTEMS            <br />            SYSTEMS FOR RESOLVATION WITH ENERGY OXYGON            <br />            CHEMICAL EXPENDITURE SYSTEMS OF THE WATER OF PISINA            <br />            SYSTEMS FOR AUTOMATIC SETTING OF BASIC WATER PARAMETERS AS PH ,            REDOX , CHLOIO , OZON , ROOM etc.",
    pool_par8:
      "       The selection of the Electromechanical equipment of the swimming pool is made by the scientific staff of our company.",

    pool_par_li1:
      "Electromechanical equipment for the construction of swimming pools, hot tub systems, water parks",
    pool_par_li2:
      "Swimming tank cleaning and maintenance appliances and accessories",
    pool_par_li3:
      "                Apparatus and instruments for the control and regulation of physicochemical characteristics of water",
    pool_par8_1:
      "        The machinery, equipment and materials used are            state-of-the-art technology and are best equipped to            you have a swimming pool that will only offer you pleasure without any            absolutely a problem.",
    pool_par9:
      "       REFERENCE FREEDOMS <br /> INSTRUCTIONS - PLAINA <br /> CLEANING STOCKS <br /> INVOLVED STOCKS <br /> SUPERVISIONAL SERVICES (SKIMMERS) <br />",
    pool_par10:
      "FILTERS -CHALAZIAKI FILTER AMMOS <br /> POLYVANA <br /> RECYCLING SYSTEM <br /> TRANSPORTERS - CENTRAL ELECTRIC TABLE <br /> EXPERIENCE SYSTEM SECURITY FOR THE MACHINE <br /> SYSTEMS FOR HEATING THE WATER OF THE BACKGROUND <br /> SYSTEM OF AUTOMATIC PAYMENT OF THE WATER OF THE BACK <br /> SAFETY SYSTEMS FOR YOUR BACK",
    pool_par11:
      "  The company Water Pool undertakes responsibly and comprehensively the maintenance of swimming pools (swimming pools-pool chemicals), Organic Cleaning with its permanent and specialized personnel. Maintenance can be done on a weekly basis or as long as necessary. Maintenance or service may be carried out at the beginning of the season or at the end of the season.",
    pool_par12_li2: "Cleaning bottoms with manual broom",
    pool_par12_li1: "     Ph and Chlorine Water Quality Control",
    pool_par12_li3: "Cleaning pump profiler",
    pool_par12_li4: "Cleaning the filter sand (Backwash & Rinse)",
    pool_par12_li5:
      " Control of chemicals (boxes - filter bucket) (Backwash & Rinse)",
    pool_par13:
      "  We cooperate with a companies that specialize in the creation of polymorphic recreation areas (water slides, water games, water park).We undertake to implement your vision in the safest and most economical way.",
    pool_par14:
      "       The swimming pool should be harmonized with the surrounding area to project it. That's why the design of the swimming pool and the surrounding area must be such that it fits your home and is functional.                <br /> Water Pool undertakes the complete design and construction of the surrounding area. It is lined with tile, stone, marble, carpeted flooring, wood etc.                <br /> There is also construction of a network of rainwater, hydraulic networks, locker rooms, WC, fencing or anything else needed.",
    titl_pool1: "POOL",
    titl_pool2: "Types of swimming pool",
    titl_pool3: "Concrete Pools",
    titl_pool4: "Procat Pools",
    titl_pool5: " Polyester Pools",
    titl_pool6:
      " Disinfection of pool water with chemicals and automatic disinfection systems- Pool chemicals",
    titl_pool7:
      "There are various disinfection systems and they are briefly listed below.",
    titl_pool8: "Εlectromechanical Pool Equipment",
    titl_pool9: "IMPLEMENTATION OF PYTHED-WORK EXPENDITURE",
    titl_pool10: "EXTERNAL EQUIPMENT",
    titl_pool11: "MECHANOSTASE",
    titl_pool12: "Pool Maintenance-Service",
    titl_pool13: "The pool service includes",
    titl_pool14: "Water slide-water park systems",
    titl_pool16: "Licensing and Operating Licenses",
    pool16_par:
      "All swimming pools for which excavation is carried out require permission from the town <br/> Water Pool undertakes the issuance of Planning Permits for swimming pools or any other construction. <br/> Public swimming pools require the issuance of an operating license by the E.O.T.<br/> The company undertakes the issuance of these Licenses. ",
    titl_pool15: "Environment Pool Area",
    titl_spa: "Spa-Hydromassage-Hammam-Sauna",
    titl_spa1: "Therapeutic Hydromassage (Jacuzzi)",
    titl_spa2: "The Philosophy and History of Hydrotherapy",
    titl_spa3: " Hydromassage: The modern form of hydrotherapy",
    titl_spa4: "The hot tub has the following benefits",
    titl_spa5: "In physical health",
    titl_spa6: "In peace of mind",
    titl_spa7: "In the aesthetics of the body",

    titl_spa8:
      "You can choose from many ready-made hot tubs in a wide variety of dimensions and designs or design and build for you a built-in hot tub in whatever dimension and shape you desire.          ",
    spa_par1:
      " Spa Areas, Therapeutic Hydromassage (Jacuzzi), Sauna- Hammam.            <br /> For whatever you want about Spa Areas, Therapeutic Hydromassage (Jacuzzi), Sauna, Hammam, we have the ideal solutions for your space.",
    spa_par2:
      "  The therapeutic hot tub (Jacuzzi) is like a small indoor pool with a water temperature 36-38°C as long as the body temperature (to oxygenate it) to offer hydrotherapy and relaxation to the body.",
    spa_par3:
      " Water is an essential element in our lives. It is a source of energy that ensures longevity, vitality and good health. The beneficial value of water is undeniable over time. In ancient Greece the use of baths was part of everyday life and a whole philosophy and means of treatment. Hydrotherapy is recommended in the use of water as a therapeutic agent. In our time it is done in a systematically and scientifically way and has been integrated into the alternative treatments of medicine.",
    spa_par4:
      "           Massage is the technique of mixing the muscles of the body and aims to treat muscle and joint problems, treat pain and fatigue and relax the body. Massage with the refreshing use of water is the most modern form of hydrotherapy, the hot tub. Hydromassage is essentially the pressure on the body with water and air ejected from special nozzles (jets) of the spa. Through the jets a mixture of water and air is launched under pressure, which creates a disturbance in the water of the spa with the direct result that the bather enjoys a universal beneficial massage. Water movement is achieved by pump operation",
    spa_par5:
      "It increases peripheral blood circulation, relieves muscles from stress caused by exercise, strenuous work or hyperactivity and achieves muscle relaxation.            <br /> stimulates the body's metabolism, reduces sensitivity to joint and muscle pain, increases muscle strength.            <br /> Increases ease of movement, joint in elderly or overworked organisms, helps remove toxins from muscles and allows absorption by the body of nutrients, helps in the treatment of rheumatic diseases and injuries.",
    spa_par6:
      "          It creates a feeling of well-being and relaxation from the stress caused by the exterminating rhythms of daily life, drastically and directly reduces the intensity of stress and anxiety.            <br /> Effectively treats phenomena of insomnia and tension, it is a pleasant activity that we can share with our company.",
    spa_par7:
      "  It helps to fight cellulite and local fat by exerting repeated pressure on skin tissues, gives vitality and care to tired skin.            <br /> dilates the pores of the skin giving the opportunity for deep and healthy cleansing.",
    titl_bio: "Biological cleanings",
    titl_bio1: "Biological purification of waste water or waste",
    bio_par:
      "  For anything related to biological cleaning by issuing permits (Approval of environmental conditions, M.P.E., urban planning and operating license) , supply-installation of E/M Equipment until the construction, operation and maintenance of <br /> The treatment of sewage treatment, carried out by biological cleaning, aims to remove, neutralize or appropriate modification of their harmful characteristics, in order to eliminate or reduce to an acceptable level the adverse to the final recipient (ground, surface water, etc.)                Consequences.                <br /> For that it is related to Organic Cleaning Facilities of settlements, hotels, camping, industries, individual residences by the method of prolonged ventilation or by compact systems.                <br /> The biological cleanings that Water Pool deals with mainly concern the treatment of urban waste water (hotels, settlements, etc.). It shall apply the prolonged ventilation system, which has been standardised  concerning  the dimensions of the tanks according to the capacity of the unit.                <br /> The wastewater or waste treatment system is a sophisticated biological sewage treatment system, designed and designed to achieve the maximum degree of purification of urban (domestic) waste water. The system applies to all countries in Europe and the United States.",
    bio_par1:
      "   The design parameters are following international standards. The wastewater supplies and organic load (BOD5) we calculate are by international standards. The proper functioning of the biological cleaning installation depends on the design parameters obtained. Because the wrong data lead to the wrong installation and thus creates the known problems of biological, (smells, unclean water, etc.).            <br /> Another way of treatment of wastewater is by the membrane bioreactor method known as MBR, namely Membrane Bio-Reactor. The method is based on the separation of Solids - Liquids of active sludge by filtering.            <br /> M.B.R. technology is essentially an active sludge method with a very intense biodegradation character of organic matter in combination with refining in place of final sedimentation tanks. The membranes are panel type of KUBOTA that is a pioneer in the manufacture of such membranes and with most applications worldwide.            <br /> For small settlements or residences we have compact systems that do not require concrete constructions and are easy to use and with excellent results.            <br /> We can make use of the clean water of organic watering (usually underground) trees etc., achieving in addition to water-saving the aesthetic improvement of the space and the elimination of odors.            <br /> Organic cleaning must give the result for which it is manufactured.            <br /> That's why our company guarantees for the installation of the biological sewage purification of your unit perfect results, which are a consequence and result of our long experience and scientific and construction expertise.            <br /> For the installation of organic cleaning of the hotel or camping etc. tell us the equivalent population of your unit and the M.P.E. Approval (if any) and we will send you your offer.",
    contact_bio: "Contact",

    contact_bio1: " with us for any information or questions you may have!",

    title_water: " Water creations - Fountains - Waterfalls",
    par_water:
      " Water pool's long-standing involvement exclusively with water ranks it among the top in the industry.                <br /> That it is related to water such as waterfall systems, fountains in many designs, water designs, water creations that beautify your space, relax the spirit, calm you, can become a reality easily and quickly.                <br /> Constructions use state-of-the-art E/M equipment with automation application and water formation rotation and simple or LED lighting rotation programs.",
    prod1: "Dichlor powder 60% 10 (Kgr) ",
    prod2: "Dichlor powder 60% 25 (Kgr)	",
    prod3: "Trichloro powder 90% 10 (Kgr)",
    prod4: "Trichlor powder 90% 25 (Kgr)	",
    prod5: "Tablet 200 gr Multi Action 10 (Kgr)",
    prod6: "Tablet 200 gr Multi Action 25 (Kgr)",
    prod7: "Tablet 200 gr simple (chlorine) 10 (Kgr)",
    prod8: "Tablet 200 gr simple (chlorine) 25 (Kgr)",
    prod9: "Active Oxygen Liquid 25 (lt) ",
    prod10: "Active Oxygen Powder 30 (Kgr)",
    prod11: "Algeacide 25 (lt)",
    prod12: "Algeacide 5 (lt)	",
    prod13: "Flocculant 25 (lt)",
    prod14: "Flocculant 5 (lt)    ",
    prod15: "PH - MINUS  powder 25 (Kgr) ",
    prod16: "PH - MINUS liquid 25 (lt	) ",
    prod17: "PH - PLUS powder 25 (Kgr)",
    prod18: "PH - PLUS liquid 25 (lt)",
    paragr_pistop:
      "Our <strong>Water Pool</strong> Company is certified since May 2015 with ISO 9001: 2008 for the Design, construction, and maintenance of water and waste projects, as well as swimming pools. We continued and were certified with the revised ISO 9001: 2015 standard from May 2018 until May 2022. On 23-2-2022 we were certified with <strong>Triple</strong> ISO System.<strong>The Quality Management System 9001: 2015, The Environmental Management System ISO 14001: 2015, and the Occupational Health and Safety Management System ISO 45001: 2018.</strong>",
    order_title: "CHEMICALS ORDER",
    order_par:
      " The swimming pool requires care and proper maintenance to maintain an ideal state of cleanliness and disinfection.              <br /> Disinfection of water is the most important thing in a swimming pool because the water must be healthy and clean. <br /> Disinfection of water is done with appropriate chemicals and at the correct dosage.              <br/> Reckless or misuse of chemicals is dangerous to health and increases costs. <br /> We will give you the appropriate instructions to always have clean and healthy water at the minimum cost and with maximum safety for your health.              <br /> Fill in the table below the quantities of chemicals you need and we will send them to you along with the instructions and dosage required.",
    order_form: "Order Form",
    bio_btn: "SEND",
    contact_title: "CONTACT US",
    contact_address: "16 KANARI, Ptolemeon &, Pilea 555 35,Thessaloniki",
    contact_title1: "Contact us for any questions!",
    contact_title2: "Ask for an offer today!",
    contact_btn: "SEND",
    contact_title: "CONTACT US",
    contact_address: "16 KANARI, Ptolemeon &, Pilea 555 35",
    contact_title1: "Contact us for any questions!",
    contact_title2: "Ask for an offer today!",
    contact_btn: "SEND",
    days_work: "Monday-Friday",
    days_off: "Saturday/Sunday",
    days_off_title: "Phone only",
    footer_contact: "Contact us",
    address_1: "Address",

    menu_home: "HOME",
    menu_company: "OUR COMPANY",
    menu_services: "SERVICES WATERPOOL",
    menu_pools: "POOLS",
    menu_spa: "SPA (HYDROMASSAGE) -HAMAM-SAUNA",
    menu_biological: "BIOLOGICAL CLEANING",
    menu_waterCreation: "WATER TREATMENT - WATER CREATIONS",
    menu_order: "ORDER OF CHEMICALS",
    menu_services2: "ELECTROMECHANICAL SERVICES",
    menu_contact: "CONTACT US",

    menu_experience: "Over 25 years of experience",

    sidebar:
      "WATER POOL is a design, construction company for swimming pools (pools), biological cleaning). Since 1998, it has been active in the field of studies and supervision, electromechanical installations of Swimming Pools, Waste and Sewage Treatment Facilities, as well as in the provision of services for full licensing coverage of these projects. <br/> WATER POOL has issued several town planning permits for Public Swimming Tanks. The company collaborates with specialized engineers of all specialties: Mechanical Engineers, Electrical Engineers, Civil Engineers, Chemical Engineers, Spatial Planning Engineers, Surveyors, Architects to fully meet the needs of customers.",
    one: "Excellent quality and high aesthetics .",
    two: "We work with qualified engineers of all specialties to fully meet the needs of customers.",
    three: "Integrated project management .",
    four: "Continuous contact with customers. ",
    five: "Over 25 years of experience.",
  },
};

export default en;
