import React, { Component } from "react";
import "./Pool.css";
import { Trans } from "react-i18next";
import { Helmet } from "react-helmet";
export class Pool extends Component {
  render() {
    return (
      <div className="full mt-md-3 mt-5" style={{ paddingTop: "10%" }}>
        <Helmet>
          <title>ΠΙΣΙΝΕΣ - WATERPOOL</title>
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <h4 className="grammata">
                {" "}
                <Trans i18nKey="titl_pool1" />
              </h4>
              <p className="paragr">
                <Trans i18nKey="pool_par1" />
              </p>
            </div>
            <div className="col-xl-6 mt-5 pt-5">
              <div
                id="demo5"
                className="carousel new-c new-c slide "
                data-ride="carousel"
              >
                {/* Indicators */}
                <ul className="carousel-indicators">
                  <li
                    data-target="#demo5"
                    data-slide-to={0}
                    className="active"
                  />
                  <li data-target="#demo5" data-slide-to={1} />
                  <li data-target="#demo5" data-slide-to={2} />
                  <li data-target="#demo5" data-slide-to={3} />
                  <li data-target="#demo5" data-slide-to={4} />
                  <li data-target="#demo5" data-slide-to={5} />
                  <li data-target="#demo5" data-slide-to={6} />
                  <li data-target="#demo5" data-slide-to={7} />
                  <li data-target="#demo5" data-slide-to={8} />
                </ul>

                {/* The slideshow */}
                <div className="carousel-inner new-ci">
                  <div className="carousel-item  active">
                    <img src={require("./pools_3.webp").default} />
                  </div>

                  <div className="carousel-item">
                    <img
                      src={require("./monopanti ypercheilisi2.webp").default}
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={require("./περιμετρικη υπερχειλιση3.webp").default}
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={
                        require("./pisína beton me monopanti ypercheilisi1.webp")
                          .default
                      }
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={
                        require("./pisína beton me monopanti ypercheilisi2.webp")
                          .default
                      }
                    />
                  </div>

                  <div className="carousel-item">
                    <img src={require("./allagi.webp").default} />
                  </div>
                  <div className="carousel-item">
                    <img src={require("./allagi2.webp").default} />
                  </div>
                  <div className="carousel-item">
                    <img src={require("./allagi3.webp").default} />
                  </div>
                  <div className="carousel-item">
                    <img src={require("./thumbnail.webp").default} />
                  </div>
                </div>
                {/* Left and right controls */}
                <a
                  className="carousel-control-prev"
                  href="#demo5"
                  data-slide="prev"
                >
                  <span className="carousel-control-prev-icon" />
                </a>
                <a
                  className="carousel-control-next"
                  href="#demo5"
                  data-slide="next"
                >
                  <span className="carousel-control-next-icon" />
                </a>
              </div>{" "}
            </div>
          </div>
          <br />
          <h4 className="grammata">
            {" "}
            <Trans i18nKey="titl_pool2" />
          </h4>
          <p className="paragr">
            <Trans i18nKey="pool_par2" />
          </p>{" "}
          {/*ΠΙΣΙΝΕΣ ΑΠΟ ΜΠΕΤΟΝ*/}
          <div className="row">
            <div className="col-xl-6">
              <h4 className="grammata">
                {" "}
                <Trans i18nKey="titl_pool3" />
              </h4>
              <p className="paragr">
                <Trans i18nKey="pool_par3" />
              </p>{" "}
            </div>
            <div
              id="demo"
              className="carousel new-c new-c slide"
              data-ride="carousel"
            >
              <ul className="carousel-indicators">
                <li data-target="#demo" data-slide-to={0} className="active" />
                <li data-target="#demo" data-slide-to={1} />
                <li data-target="#demo" data-slide-to={2} />
                <li data-target="#demo" data-slide-to={3} />
              </ul>
              <div className="carousel-inner new-ci">
                <div className="carousel-item active">
                  <img
                    src={require("./mpetonmeparalia.webp").default}
                    alt="beton_sea1"
                  />
                </div>

                <div className="carousel-item">
                  <img src={require("./beton_1.webp").default} alt="beton1" />
                </div>
                <div className="carousel-item">
                  <img src={require("./beton_2.webp").default} alt="beton2" />
                </div>
                <div className="carousel-item">
                  <img src={require("./beton_3.webp").default} alt="beton3" />
                </div>
                <div className="carousel-item">
                  <img src={require("./beton_4.webp").default} alt="New York" />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#demo"
                data-slide="prev"
              >
                <span className="carousel-control-prev-icon" />
              </a>
              <a
                className="carousel-control-next"
                href="#demo"
                data-slide="next"
              >
                <span className="carousel-control-next-icon" />
              </a>
            </div>
          </div>
          <br />
          {/*Πισίνες προκάτ*/}
          <div className="row">
            <div className="col-xl-6">
              <h4 className="grammata">
                {" "}
                <Trans i18nKey="titl_pool4" />
              </h4>
              <p className="paragr">
                <Trans i18nKey="pool_par4" />
              </p>{" "}
            </div>
            <div
              id="demo1"
              className="carousel new-c slide"
              data-ride="carousel"
            >
              <ul className="carousel-indicators">
                <li data-target="#demo1" data-slide-to={0} className="active" />
                <li data-target="#demo1" data-slide-to={1} />
              </ul>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={require("./prokat_1.webp").default} alt="beton1" />
                </div>
                <div className="carousel-item">
                  <img src={require("./prokat_2.webp").default} alt="beton2" />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#demo1"
                data-slide="prev"
              >
                <span className="carousel-control-prev-icon" />
              </a>
              <a
                className="carousel-control-next"
                href="#demo1"
                data-slide="next"
              >
                <span className="carousel-control-next-icon" />
              </a>
            </div>
          </div>
          <br />
          {/* Πισίνες πολυεστερικες*/}
          <div className="row mt-5">
            <div className="col-xl-6 mt-5">
              <h4 className="grammata">
                {" "}
                <Trans i18nKey="titl_pool5" />
              </h4>
              <p className="paragr">
                <Trans i18nKey="pool_par5" />
              </p>
            </div>{" "}
            <div
              id="demo155"
              className="carousel new-c slide mt-4"
              data-ride="carousel"
            >
              <ul className="carousel-indicators">
                <li
                  data-target="#demo155"
                  data-slide-to={0}
                  className="active"
                />
                <li data-target="#demo155" data-slide-to={1} />
              </ul>
              <div className="carousel-inner new-ci">
                <div className="carousel-item active">
                  <img
                    src={require("./polysterikí1.webp").default}
                    alt="πολυεστερικη"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={require("./pisína polysterikí.webp").default}
                    alt="πολυεστερικη"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#demo155"
                data-slide="prev"
              >
                <span className="carousel-control-prev-icon" />
              </a>
              <a
                className="carousel-control-next"
                href="#demo155"
                data-slide="next"
              >
                <span className="carousel-control-next-icon" />
              </a>
            </div>
          </div>
          <br />
          <h4 className="grammata">
            <Trans i18nKey="titl_pool6" />
          </h4>
          <p className="paragr">
            <Trans i18nKey="pool_par6" />
          </p>
          <h4 className="grammata">
            <Trans i18nKey="titl_pool7" />
          </h4>
          <p className="paragr">
            <Trans i18nKey="pool_par7" />
          </p>
          <h4 className="grammata">
            {" "}
            <Trans i18nKey="titl_pool8" />
          </h4>
          <p className="paragr">
            <Trans i18nKey="pool_par8" />
            <br />
            <ul>
              <li>
                {" "}
                <Trans i18nKey="pool_par_li1" />
              </li>
              <li>
                <Trans i18nKey="pool_par_li2" />
              </li>
              <li>
                <Trans i18nKey="pool_par_li3" />
              </li>{" "}
            </ul>{" "}
            <Trans i18nKey="pool_par8_1" />
          </p>
          <br />
          <h4 className="grammata">
            <Trans i18nKey="titl_pool9" />
          </h4>
          <p className="paragr"></p>
          <h4 className="grammata">
            {" "}
            <Trans i18nKey="titl_pool10" />
          </h4>
          <p className="paragr">
            <Trans i18nKey="pool_par9" />
          </p>
          <h4 className="grammata">
            {" "}
            <Trans i18nKey="titl_pool11" />
          </h4>
          <p className="paragr">
            <Trans i18nKey="pool_par10" />
          </p>
          <h4 className="grammata">
            {" "}
            <Trans i18nKey="titl_pool12" />
          </h4>
          <p className="paragr">
            <Trans i18nKey="pool_par11" />
          </p>
          <h4 className="grammata">
            {" "}
            <Trans i18nKey="titl_pool13" />:
          </h4>
          <p className="paragr">
            <ul>
              <li>
                {" "}
                <Trans i18nKey="pool_par12_li1" />
              </li>
              <li>
                {" "}
                <Trans i18nKey="pool_par12_li2" />{" "}
              </li>
              <li>
                <Trans i18nKey="pool_par12_li3" />
              </li>
              <li>
                {" "}
                <Trans i18nKey="pool_par12_li4" />
              </li>
              <li>
                <Trans i18nKey="pool_par12_li5" />
              </li>
            </ul>
          </p>
          <br />
          {/*WaterPark*/}
          <div className="row">
            <div className="col-xl-6">
              <h4 className="grammata">
                {" "}
                <Trans i18nKey="titl_pool14" />
              </h4>
              <p className="paragr">
                <Trans i18nKey="pool_par13" />
              </p>
            </div>
            <div
              id="demo2"
              className="carousel new-c slide"
              data-ride="carousel"
            >
              <ul className="carousel-indicators">
                <li data-target="#demo2" data-slide-to={0} className="active" />
                <li data-target="#demo2" data-slide-to={1} />
              </ul>
              <div className="carousel-inner new-ci">
                <div className="carousel-item active">
                  <img
                    src={require("./waterpark_1.webp").default}
                    alt="waterpark_1"
                    width={500}
                    height={500}
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={require("./waterpark_2.webp").default}
                    alt="waterpark_2"
                    width={500}
                    height={500}
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#demo2"
                data-slide="prev"
              >
                <span className="carousel-control-prev-icon" />
              </a>
              <a
                className="carousel-control-next"
                href="#demo2"
                data-slide="next"
              >
                <span className="carousel-control-next-icon" />
              </a>
            </div>
          </div>
          <br />
          <div className="mt-5">
            <div>
              <h4 className="grammata">
                {" "}
                <Trans i18nKey="titl_pool16" />
              </h4>
              <p className="paragr">
                <Trans i18nKey="pool16_par" />
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-xl-6">
              <h4 className="grammata">
                {" "}
                <Trans i18nKey="titl_pool15" />
              </h4>
              <p className="paragr">
                <Trans i18nKey="pool_par14" />
              </p>
            </div>

            <div className="col-xl-6 mt-4 mb-5">
              <img src={require("./perivalon_1.webp").default} width="540px" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pool;
